import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";
export default class extends Controller {
    connect() {
        useResize(this);
        window.addEventListener('resize', this.setDocumentHeight);
        this.setDocumentHeight();
        window.addEventListener('resize', this.setNavigationHeight);
        window.addEventListener('scroll', this.setNavigationHeight);
        this.setNavigationHeight();
    }
    setDocumentHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    }
    setNavigationHeight() {
        const doc = document.documentElement;
        const nav = document.getElementById('umla-nav');
        const navClaims = document.getElementById('umla-nav-claims');
        const navMain = document.getElementById('umla-nav-main');
        let navHeight = 0;
        // scroll position from top
        if (window.scrollY > (navClaims?.clientHeight || 0)) {
            navHeight = navMain?.clientHeight || 0;
        }
        else {
            navHeight = nav?.clientHeight || 0;
        }
        doc.style.setProperty('--nav-height', `${navHeight}px`);
    }
    // noinspection JSUnusedGlobalSymbols
    resize() {
        this.setDocumentHeight();
    }
}
