import * as Sentry from "@sentry/browser";
Sentry.init({
    dsn: "https://13bc48b9a3ec4fcd8fbedeb2093e011d@sentry.umfulana.com/8",
    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: "my-project-name@2.3.12",
    integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration({
        // 	mutationBreadcrumbLimit: 1000,
        // 	mutationLimit: 500,
        // }),
    ],
    // replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 1.0,
    environment: /www\.umfulana\.de/.test(document.location.href) ? 'prod' : 'dev',
    tracesSampler: samplingContext => {
        const url = samplingContext.location?.href || '';
        if (/www\.umfulana\.de/.test(url)) {
            if (/www\.umfulana\.de\/mein/.test(url)) {
                return 1;
            }
            return 0.1;
        }
        return 1;
    }
});
