export default class Store {
    controller;
    constructor(controller) {
        this.controller = controller;
    }
    get key() {
        return this.controller.storeKeyValue;
    }
    get initialPath() {
        return this.controller.initialPathValue;
    }
    get nodes() {
        return this.controller.nodes;
    }
    get openedNodes() {
        return this.controller.openedNodes;
    }
    load() {
        if (!this.key)
            return;
        let ids = Store.load(this.key);
        if (!ids)
            return;
        if (this.initialPath) {
            const count = this.initialPath.split(' > ').length;
            ids = this.initialPath.split(' > ').map((_) => this.initialPath);
            for (let ii = count - 1; ii > 0; ii--) {
                ids[ii] = ids[ii].split(' > ').slice(0, ii * -1).join(' > ');
            }
            ids = ids.reverse();
        }
        let idSet = new Set(ids);
        this.nodes.forEach((node) => {
            if (idSet.has(node.getAttribute('data-node-id'))) {
                this.controller.show(node);
                node.classList.add('text-blue-500');
            }
            else {
                this.controller.hide(node);
                node.classList.remove('text-blue-500');
            }
        });
    }
    save() {
        if (!this.key)
            return;
        let ids = this.openedNodes.map((node) => node.getAttribute('data-node-id'));
        Store.save(this.key, ids);
    }
    static load(key) {
        let json = sessionStorage.getItem(key);
        try {
            return JSON.parse(json);
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
    static save(key, value) {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
}
