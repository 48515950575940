export class Usercentrics {
    static hasConsent(name) {
        if (typeof window.UC_UI === 'undefined') {
            return null;
        }
        return window.UC_UI && window.UC_UI.getServicesBaseInfo()
            .find((data) => (data.id === Usercentrics.getIdFromName(name))).consent.status;
    }
    static getIdFromName(name) {
        let id = '';
        switch (name.toLowerCase()) {
            case 'mapbox':
                id = 'U8QkTd2W';
                break;
            case 'youtube':
                id = 'BJz7qNsdj-7';
                break;
            case 'googlemaps':
                id = 'S1pcEj_jZX';
                break;
            case 'googleanalytics4':
                id = '87JYasXPF';
                break;
            case 'matomo':
                id = 'u6fxocwTs';
                break;
        }
        if (!id) {
            console.error(`ID für ${name} nicht gefunden!`);
        }
        return id;
    }
    static getMapboxOverlay() {
        const text = `Wir verwenden Mapbox, um Karten einzubetten. Dieser
            Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details
            durch und stimmen Sie der Nutzung des Service zu, um diese Karte
            anzuzeigen.`;
        return Usercentrics.getOverlay('Mapbox', Usercentrics.getIdFromName('mapbox'), text);
    }
    static getYouTubeOverlay() {
        const text = `Wir verwenden einen Service eines Drittanbieters, um Videoinhalte einzubetten. Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch und stimmen Sie der Nutzung des Service zu, um dieses Video anzusehen.`;
        return Usercentrics.getOverlay('YouTube Video', Usercentrics.getIdFromName('youtube'), text);
    }
    static getGoogleMapsOverlay() {
        const text = `Wir verwenden einen Service eines Drittanbieters, um Karteninhalte einzubetten. Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch und stimmen Sie der Nutzung des Service zu, um diese Karte anzuzeigen.`;
        return Usercentrics.getOverlay('Google Maps', Usercentrics.getIdFromName('googlemaps'), text);
    }
    static getOverlay(name, id, text) {
        return `<div class="usercentrics_consent-layer w-full h-full bg-gray-200 flex items-center justify-center">
    <div class="max-w-[80%] max-h-[80%] w-96 overflow-y-auto bg-white shadow-md rounded-md p-4 flex flex-col gap-y-2 items-center">
        <div class="text-center font-bold text-lg leading-tight">Wir benötigen Ihre Zustimmung, um den
            ${name}-Service zu laden!
        </div>
        <p class="text-center mb-2 mt-0 text-gray-600 leading-tight">${text}</p>
        <button type="button" onclick="UC_UI.acceptService('${id}'); return false;"
                class="btn-blue btn-block"> Akzeptieren
        </button>
        <button type="button"
                onclick="UC_UI.showSecondLayer('${id}'); return false;"
                class="btn-gray btn-block"> Mehr Informationen
        </button>
        <div class="text-center text-xs text-gray-500 mt-2">
            powered by <a target="_blank"
                          class="text-gray-500 hover:text-blue-600 hover:underline"
                          href="https://usercentrics.com/" rel="nofollow noopener">Usercentrics
                Consent Management Platform</a></div>
    </div>
</div>`;
    }
}
