import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
    static targets = [
        'content',
        'button',
    ];
    static classes = [
        'toggle'
    ];
    moreText = 'Mehr';
    lessText = 'Weniger';
    connect() {
        if (!this.hasToggleClass) {
            this.toggleClasses = ['line-clamp-3'];
        }
    }
    toggle() {
        if (this.hasContentTarget && this.hasButtonTarget) {
            const doesContain = this.toggleClasses.every((c) => this.contentTarget.classList.contains(c));
            if (doesContain) {
                this.contentTarget.classList.remove(...this.toggleClasses);
                this.buttonTarget.innerText = this.lessText;
            }
            else {
                this.contentTarget.classList.add(...this.toggleClasses);
                this.buttonTarget.innerText = this.moreText;
            }
        }
    }
}
