import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    static values = {
        address: String
    };
    connect() {
        if (this.hasAddressValue) {
            this.element.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                location.href = `mailto:${this.addressValue}@umfulana.com`;
            });
        }
    }
}
