// @flow
const localeDe = {
    'AttributionControl.ToggleAttribution': 'Zuordnung umschalten',
    'AttributionControl.MapFeedback': 'Rückmeldung zur Karte',
    'FullscreenControl.Enter': 'Vollbildmodus',
    'FullscreenControl.Exit': 'Vollbildmodus verlassen',
    'GeolocateControl.FindMyLocation': 'Meinen Standort finden',
    'GeolocateControl.LocationNotAvailable': 'Standort nicht verfügbar',
    'LogoControl.Title': 'Mapbox Logo',
    'NavigationControl.ResetBearing': 'Peilung auf Nord zurücksetzen',
    'NavigationControl.ZoomIn': 'Vergrößern',
    'NavigationControl.ZoomOut': 'Verkleinern',
    'ScaleControl.Feet': 'ft',
    'ScaleControl.Meters': 'm',
    'ScaleControl.Kilometers': 'km',
    'ScaleControl.Miles': 'mi',
    'ScaleControl.NauticalMiles': 'nm',
    'ScrollZoomBlocker.CtrlMessage': 'Strg + Scrollen zum Zoomen der Karte',
    'ScrollZoomBlocker.CmdMessage': '⌘ + Scrollen zum Zoomen der Karte',
    'TouchPanBlocker.Message': 'Verschieben der Karte mit zwei Fingern'
};
export default localeDe;
