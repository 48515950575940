import { ApplicationController } from "stimulus-use";
export default class default_1 extends ApplicationController {
    static targets = ["counterView"];
    static values = {
        url: String
    };
    connect() {
    }
    // noinspection JSUnusedGlobalSymbols
    refreshTotal(e) {
        fetch(this.urlValue)
            .then(response => {
            return response ? response.json() : null;
        })
            .then(data => {
            if (this.hasCounterViewTarget) {
                this.counterViewTarget.classList[data.count ? 'remove' : 'add']('scale-0');
            }
        });
    }
}
